/*** FONTS ***/
@import url(https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i);
@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

*:lang(ar) {
  font-family: 'El Messiri', sans-serif;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: white;
}

html {
  scroll-behavior: revert !important;
}


/* SWIPER */
.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  font-size: 21px !important;
  font-weight: 900;
  text-transform: none !important;
  letter-spacing: 0;
  /* font-feature-settings: ; */
  font-variant: initial;
  line-height: 1;
  color: #6a6a6a;
  padding: 8px 13px !important;
  background: white;
  box-shadow: -2px 1px 4px #00000066;
  border-radius: 20px !important;
  /* z-index: +999; */
}

/* SWIPER */
/*    ************************* Nav Bar Start *******************************   */

.top-header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .top-bar-bna {
  background: linear-gradient(45deg, #63a953, #b1eba2) !important;
}

.top-bar-agb {
  background: linear-gradient(90deg, #487db3, #004b85) !important;
}

.top-bar-alsalam {
  background: linear-gradient(161deg, #2e5e55, #49a18f) !important;
} */

.top-bar {
  height: 47px;
  /* background: #293847; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4416b;
  background-image: url("img/bg.jpg");
}

.top-bar p {
  color: white;
  font-size: 15px;
  font-family: 'Poppins';
  margin-bottom: 0;
  font-weight: 700;
}


@media only screen and (max-width: 992px) {
  .top-bar p {
    font-size: 12px;
  }
}

.top-bar-link {
  font-size: 15px;
  display: flex;
}

.top-bar-link a {
  display: flex;
  align-items: center;
}

.top-bar-link p {
  font-size: 15px;
}

.top-bar-link a:first-child {
  border-right: 1px solid white;
  /* padding-right: 10px; */
}

.top-bar-link svg {
  width: 22px;
  margin-right: 8px;
}

.nav-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.nav-first-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.input-group {
  width: 50% !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fff !important;
  outline: 0;
  box-shadow: 0 0 10px 0.25rem rgb(181 181 181 / 24%) !important;
}

.btn-search-dd {
  background: linear-gradient(45deg, #E0234F, #FF517A);
  color: white;
  border-radius: 0 100px 100px 0px;
  border: none;
  width: 103px;
  height: 45px;
}

.btn-search-dd:hover {
  background: linear-gradient(231deg, #e25e7e, #fa7291);
}

.input-search-dd {
  border-radius: 60px 0px 0px 60px !important;
  padding-left: 28px !important;
}

.input-search-dd::placeholder {
  color: #8F8888;
  font-size: 15px;
}

.nav-icons-section {
  display: flex;
  align-items: center;
}

.nav-icons-section p {
  color: #717171;
  font-size: 13px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
}

.icons-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icons-nav {
  margin-right: 18px;
}

.navbar .nav-link.active {
  color: #E0234F !important;
  font-weight: 600;
  padding-left: 0px !important;
}

.navbar a.nav-link svg circle {
  transition: all .6s;
}

.navbar a.nav-link:hover svg circle {
  /* fill: #a2a2a2; */
  fill: #dddddd;
  transition: all .6s;

}

.navbar a.nav-link:hover g path {
  fill: #ff5e84;
  box-shadow: 0px -2px 8px white;
}

.nav-second-section {
  border-top: 1px solid #D2D2D2;
  margin-top: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.btn-call-nav {
  background: linear-gradient(45deg, #f84670, #e42854);
  color: white;
  padding: 6px 10px;
  border-radius: 41px;
  font-size: 13px;
  margin-left: auto;

}

.store-btn {
  /* background: linear-gradient(45deg, #41f6ff, #375adb) !important; */
  background: linear-gradient(45deg, #fc41ff, #375adb) !important;
}

.store-btn svg {
  width: 31px;
  height: auto;
  margin-right: 8px;
}

.store-btn:hover {
  background: linear-gradient(231deg, #91db63, #4de57c) !important;
}

.btn-call-nav:hover {
  background: linear-gradient(231deg, #91db63, #4de57c);
  transition: all .9s;
}

@media only screen and (max-width: 1200px) {
  /* .store-btn {
    font-size: 12px;
  } */

  .store-btn svg {
    display: none;
    width: 26px;
    height: auto;
    margin-right: 5px;
  }

}

.navbar .nav-link {
  color: #686868 !important;
}

.nav-items {
  position: relative;
}

.navbar .nav-fr::after {
  height: 19px;
  content: ' ';
  border-left: 1px #707070 solid;
  margin-left: 17px;
}

.navbar .nav-ar::before {
  height: 19px;
  content: ' ';
  border-right: 1px #707070 solid;
  margin-right: 17px;
}

@media only screen and (max-width: 1200px) {

  .navbar .nav-fr::after {
    height: 19px;
    content: ' ';
    border-left: 1px #707070 solid;
    margin-left: 3px;
  }

  .navbar .nav-ar::before {
    height: 19px;
    content: ' ';
    border-right: 1px #707070 solid;
    margin-right: 3px;
  }

  .navbar .nav-link.active {
    color: #E0234F !important;
    font-weight: 600;
    padding-left: 0px !important;
    padding-right: 0px;
  }


}

.navbar .nav-link-last-fr:last-child:after {
  height: 19px;
  content: ' ';
  border-left: 1px #707070 solid;
  margin-left: 17px;
  display: none;
}

.navbar .nav-link-last-ar:last-child:before {
  height: 19px;
  content: ' ';
  border-left: 1px #707070 solid;
  margin-left: 17px;
  display: none;
}

.navbar a.nav-link svg {
  margin-right: 6px;
}



.lang-section {
  z-index: +9999999999;
}

i {
  font-family: var(--fa-style-family, "Font Awesome 6 Free") !important;
}

.lang-section .lang-menu {
  width: 29px;
  height: 28px;
  border-radius: 100%;
  object-fit: cover;
  /* box-shadow: 0px 1px 8px 4px #ffffffb8; */
  color: white;
  margin-right: 8px;
}

.lang-section .sl-nav li:hover .pad-lang {
  position: absolute;
  top: -32px;
  padding-right: 30px;
  padding-left: 65px;
  padding-top: 40px;
  left: 25px;
}

.lang-section .sl-nav {
  display: inline;
}

.lang-section .sl-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.lang-section .sl-nav li {
  cursor: pointer;
  /* padding-bottom: 10px; */
}

.lang-section .sl-nav li ul {
  display: none;
}

.lang-section .sl-nav li:hover ul {
  position: absolute;
  top: 29px;
  right: -15px;
  display: block;
  background: #fff;
  width: 120px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.lang-section .sl-nav li:hover .triangle {
  position: absolute;
  top: 15px;
  right: -10px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;
}

.lang-section .sl-nav li:hover .triangle:after {
  content: "";
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.lang-section .sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-right: 2px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
}

.lang-section .sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}

.lang-section .sl-nav li ul li span {
  padding-left: 5px;
}

.lang-section .sl-nav li ul li span:hover {
  color: #61b4ee;
}

.lang-section .sl-nav li ul li span.active {
  color: #61b4ee;
  font-weight: 700;
}

.lang-section .sl-flag {
  display: inline-block;
  /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4); */
  width: 18px;
  height: 18px;
  background: #e9244f;
  border-radius: 50%;
  position: relative;
  top: 2px;
  overflow: hidden;
}

.lang-section .flag-fr {
  /* background: url("https://icons.iconarchive.com/icons/wikipedia/flags/512/FR-France-Flag-icon.png"); */
  background-size: cover;
  background-position: center center;
}

.lang-section .flag-usa {
  background-size: cover;
  background-position: center center;
  /* background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAABhUlEQVQ4T2Ows82PjGixsc4LD2tysC/09Kjw8622tyuICG8u0w/cpGSCBzF4e1VmZkzw9anOzOj38a4KCW4IC22ECHYk1l9tn4gHMeTlTnZxLikvm+XiUpKW2hvgX+vnV5OVOQEoOGfOtv94AYOzU3Fd7XxHh6Lq6rlurqUx0W0J8Z1AnbW18yotonaYuOJBDBXls4A+bGpaBCTz86YEBtQCvVBSPAPIbY0oP1/aiAcxABU1Ny+2tclvbFjo5FgUF9uenNwNDLnmpkWEnV1TPRcY1O1tS4H6i4umA/0MDK2K8tlAwRqHpP1uoXgQKKraWpcClTY3LQZaCLQ5NaUX5OaWJY3++SeTC/AgBmA4AXUClUJs9ver8fKsAAYEUJCws4G21dXNB1oFdD/Qz8DQTk4C+bm2dn6DZ9bRiDQ8iAEYt8CoBpK5YBIYw0AEEZwSXX4oMB4PYoC6gCzAcDqrjGzEsMfen2xEmbMv1rSTjRi26dqRjShz9o2+6WQjBrSShQSkZAIADvW/HLrLY6cAAAAASUVORK5CYII="); */
}

.lang-section .lang-text {
  font-size: 14px;
}







.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 6rem !important;
  height: 6rem !important;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}


@media only screen and (max-width: 992px) {

  .humberger li a {
    color: #464646;
  }

  .humberger li a svg {
    margin-right: 9px;
  }

  /* .humberger { 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  } */
  a.navbar-brand img {
    width: 57px;
    height: auto;
  }

  .navbar-nav {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start !important;
  }

  .nav-link::after {
    content: none !important;
  }

  .btn-call-nav {
    margin-bottom: 20px !important;
    margin-right: auto;
    width: fit-content;
  }

  .input-group {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .nav-icons-section {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .input-group {
    margin: auto;
    margin-bottom: 20px;
  }

  .nav-second-section {
    border-top: 1px solid #D2D2D2;
    margin-top: 6px;
    padding-top: 4px;
    padding-bottom: 9px;
  }
}


section.diar-box {
  padding: 48px 0px;
  background: #F5F6F9;
}

section.diar-box img {
  border-radius: 20px;
}

.diar-animation {
  transition: all .3s;
}

.diar-animation:hover {
  transform: scale(1.05);
}

section.diar-box img {
  /* border-radius: 20px; */
  /* height: auto; */
  width: 100%;
  /* object-fit: cover;
  object-position: left;
  max-height: 280px; */
}

section.slider {
  /* min-height: 600px; */
  background: #efefef;
  margin: auto;
}

/* .slider .carousel-item img {
  width: 100%;
  min-height: 304px;
  object-fit: cover;
} */
.slider .swiper-slide img {
  width: 100%;
  min-height: 304px;
  object-fit: cover;
}

@media only screen and (max-width: 992px) {
  section.diar-box {
    padding: 93px 0px;
    background: #F5F6F9;
  }
}





.title-section {
  text-align: left;
  font-size: 23px;
  color: #605E5E;
  margin-bottom: 22px;
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
}

.title-section:after {
  content: "";
  position: absolute;
  border-bottom: 2px solid #DBDBDB;
  width: 100%;
  height: 20px;
  display: inline;
  margin-left: 1em;
}



.top-annonces,
.dar-taksit,
.draidjar-pub,
.engagements {
  background: #F5F6F9;
}





.brands {
  /* margin-top: 47px;
  margin-bottom: 138px; */
}

.brands img {
  max-height: 107px;
}

.brands .swiper-wrapper {
  align-items: center;
}


.card-product {
  height: 314px;
  width: 215px;
  background: white;
  border-radius: 7px;
  /* padding: 10px 10px 10px 10px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin: auto;

}

.first-section-product {
  padding: 20px 18px 0px 12px;
}

.title-product {
  color: #42464A;
  font-size: 14px;
  font-weight: 600;
  margin-top: 13px;
}

.mark-product {
  color: #ABABAB;
  font-size: 11px;
}

.price-product {
  background: linear-gradient(45deg, #e03375, #ed5859);
  padding: 13px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 4px;
}

.tag-taksit {
  background: white;
  padding: 1px 44px;
  border-radius: 21px;
  margin-bottom: 4px;
  color: #eb535d;
  font-weight: 700;
  font-size: 17px;
}

.product-taksit-bank {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-page .bank-img-simulation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  border-left: 1px solid #cbcaca;
  padding-left: 20px;
}

.price {
  color: white;
  font-size: 17px;
  font-weight: 700;
}

.price span {
  font-size: 15px;
  font-weight: normal;
}

.img-product {
  min-height: 154px;
  max-height: 154px;
  object-fit: cover;
  overflow: hidden;
}

/* Brand Icons */






/* START DAR I3MAR */
section.dar-i3mar {
  background-image: url("img/dari3marbg.jpg");
  /* min-height: 700px; */
  padding-top: 90px;
  padding-bottom: 158px;
  background-repeat: no-repeat;
  background-size: cover;
}

.dar-i3mar h1 {
  font-weight: bold;
  font-size: 66px;
  color: #4cab47;
}

.dar-i3mar h2 {
  padding-top: 125px;
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 25px;
}

.dar-i3mar p {
  font-size: 19px;
  padding-bottom: 77px;
  line-height: 26px;
  max-width: 72%;
}

@media only screen and (max-width: 992px) {
  .dar-i3mar h1 {
    font-size: 50px;
  }

  .dar-i3mar h2 {
    font-size: 30px;
  }

  .dar-i3mar p {
    max-width: 100%;
  }
}

.dari3mar-head {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
  height: 362px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #7ff6ad29, #37d9e18a), url(https://image.ues.bg/articles/origin_thumbs_1200/747/143094.jpg);
  background-position: center;
}

.dari3mar-head img {
  margin-bottom: -239px;
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
  padding: 27px;
  border-radius: 20px;
}


.dar-i3mar-page ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
}

.dar-i3mar-page ol.gradient-list>li {
  background: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  margin-top: 1rem;
  min-height: 3rem;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
}

.dar-i3mar-page ol.gradient-list>li::before,
ol.gradient-list>li::after {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
  border-radius: 1rem 1rem 0 1rem;
  content: "";
  height: 3rem;
  left: -1rem;
  overflow: hidden;
  position: absolute;
  top: -1rem;
  width: 3rem;
}

.dar-i3mar-page ol.gradient-list>li::before {
  align-items: flex-end;
  content: counter(gradient-counter);
  color: white;
  display: flex;
  font: 900 1.5em/1 "Montserrat";
  justify-content: flex-end;
  padding: 0.125em 0.25em;
  z-index: 1;
}

.dar-i3mar-page ol.gradient-list>li::before,
ol.gradient-list>li::after {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
  border-radius: 1rem 1rem 0 1rem;
  content: "";
  height: 3rem;
  left: -1rem;
  overflow: hidden;
  position: absolute;
  top: -1rem;
  width: 3rem;
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+1):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+2):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+3):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+4):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+5):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+6):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+7):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+8):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+9):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li:nth-child(10n+10):before {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
}

.dar-i3mar-page ol.gradient-list>li+li {
  margin-top: 2rem;
}

/* END DAR I3MAR */



.draidjar-pub img {
  padding: 17px;
  background: white;
  box-shadow: 1px 1px 12px #e1e1e1;
}


/* START DAR I3MAR */
section.dar-idjar {
  background-image: url("img/daridjarbg.jpg");
  /* min-height: 700px; */
  padding-top: 90px;
  padding-bottom: 158px;
  background-repeat: no-repeat;
  background-size: cover;
}

.dar-idjar h1 {
  font-weight: bold;
  font-size: 66px;
  color: #4cab47;
}

.dar-idjar h2 {
  padding-top: 125px;
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 25px;
}

.dar-idjar p {
  font-size: 19px;
  padding-bottom: 77px;
  line-height: 26px;
  max-width: 72%;
}

@media only screen and (max-width: 992px) {
  .dar-idjar h1 {
    font-size: 50px;
  }

  .dar-idjar h2 {
    font-size: 30px;
  }

  .dar-idjar p {
    max-width: 100%;
  }
}

/* END DAR I3MAR */


.titre {
  font-size: 36px;
  font-weight: 600;
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin: 8px 0px;
}

.titre:before,
.titre:after {
  content: "";
  flex-grow: 1;
  background: #E0234F;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}



/* START FOOTER  */
section.footer {
  background: linear-gradient(193deg, #5e5e5e, #343434);
  /* height: 301px; */
  /* margin-top: 200px; */
}

section.footer p {
  color: white;
}

.logo-footer {
  margin-right: 34px;
  border-right: 1px solid white;
  padding-right: 20px;
}

.text-footer {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.text-footer p {
  color: white;
  font-size: 13px;
  line-height: 14px;
}

.copyright {
  font-size: 18px;
  font-weight: 800;
  color: white;
}

.contact-footer p {
  margin-bottom: 7px;
  font-size: 15px;
}

@media only screen and (max-width: 992px) {
  .footer {
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  .copyright-logo {
    align-items: center !important;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 25px;
  }

  .logo-footer {
    border-right: none;
    padding-right: 0px;
    margin-right: 0px;
    margin-bottom: 13px;
  }
}

.footer-icon svg {
  margin-right: 11px;
}

/* END FOOTER */



.react-multi-carousel-item {
  margin: auto;
}

li.react-multi-carousel-item {
  margin-left: -2px;
}

.PopupBank {
  background: #00000091;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  top: 0;
  justify-content: center;
  z-index: 9999;
  align-items: center;
}

.popuptitel {
  background: linear-gradient(45deg, #e56a86, #ff5555);
  padding-top: 4px;
  padding-bottom: 30px;
  color: white;
}

.PopupContent {
  width: 81%;
  height: auto;
  background: white;
  border-radius: 26px;
  overflow: hidden;
  box-shadow: 1px -2px 20px 0px #ffffff94;
  padding-bottom: 63px;
  max-width: 811px;
}

.PopupContent h1 {
  text-align: center;
  margin-top: 34px;
  color: white;
}

.PopupContent H3 {
  text-align: center;
  font-size: 20px;
  color: #ffdbd1;
}

.Bank-section {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 51px;
  flex-wrap: wrap;
  max-width: 597px;
}

.bna-section img {
  max-width: 98px;
  border-radius: 24px;
}

.desc span {
  font-size: 13px;
  font-weight: 500;
}

.bna-section {
  background: #ffffff;
  padding: 16px 15px 16px 15px;
  box-shadow: -3px 3px 15px #cfcfcf;
  border-radius: 20px;
  text-align: center;
  margin-top: 31px;
  min-width: 134px;
  margin-left: 27px;
  margin-right: 27px;

}

.bna-section:hover {
  background: #d3d3d3;
  cursor: pointer;
  transition: 1s;
}

.bna-section:hover .desc {
  color: white;
}

.desc {
  margin-top: 13px;
  color: #6a6a6a;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}



















/* Bank Section */

.bank .nav-wrapper {
  /* width: 300px; */
  /* margin: 100px auto; */
  /* text-align: center; */
}

.bank .sl-nav {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bank-icon {
  width: 40px;
  border-radius: 100%;
  overflow: hidden;
  min-width: 40px;
  min-height: 40px;
  max-height: 40px;
  max-width: 40px;
}

.bank-desc {
  color: #717171;
  font-size: 13px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
}

.bank .sl-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.bank .sl-nav li {
  cursor: pointer;
  /* padding-bottom: 10px; */
}

.bank .sl-nav li ul {
  display: none;
}

/* .bank .sl-nav:hover ul ul {
  position: absolute;
  top: 14px;
  right: -15px;
  display: block;
  background: #fff;
  width: 135px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
} */

.bank .display-menu-bank {
  position: absolute !important;
  top: 14px;
  right: -15px;
  display: block !important;
  background: #fff;
  width: 135px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.bank .triangle-bank {

  position: absolute;
  top: 1px;
  right: -10px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;

}

.bank .triangle-bank:after {
  content: "";
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

/* .bank .sl-nav li:hover .triangle {
  position: absolute;
  top: 1px;
  right: -10px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;
} */

/* .bank .sl-nav li:hover .triangle:after {
  content: "";
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
} */

.bank .sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bank .sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}

.bank .sl-nav li ul li span {
  padding-left: 5px;
}

.bank .sl-nav li ul li span:hover,
.bank .sl-nav li ul li span.active {
  color: #454545;
  font-weight: 600;
}

.bank .sl-flag {
  display: inline-block;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  width: 28px;
  height: 28px;
  background: #aaa;
  border-radius: 50%;
  position: relative;
  top: 2px;
  overflow: hidden;
}

.bank .flag-de {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAAAPUlEQVQ4T+3HMQ0AIBTE0NOHM8x9B7hgh71bIWGieUvze1m7kHGBr/AVvsJX+EpmP5dV5/gKX+ErfIUvVDYcX2NMxQC8PAAAAABJRU5ErkJggg==");
  background-size: cover;
  background-position: center center;
}

.bank .flag-usa {
  background-size: cover;
  background-position: center center;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAABhUlEQVQ4T2Ows82PjGixsc4LD2tysC/09Kjw8622tyuICG8u0w/cpGSCBzF4e1VmZkzw9anOzOj38a4KCW4IC22ECHYk1l9tn4gHMeTlTnZxLikvm+XiUpKW2hvgX+vnV5OVOQEoOGfOtv94AYOzU3Fd7XxHh6Lq6rlurqUx0W0J8Z1AnbW18yotonaYuOJBDBXls4A+bGpaBCTz86YEBtQCvVBSPAPIbY0oP1/aiAcxABU1Ny+2tclvbFjo5FgUF9uenNwNDLnmpkWEnV1TPRcY1O1tS4H6i4umA/0MDK2K8tlAwRqHpP1uoXgQKKraWpcClTY3LQZaCLQ5NaUX5OaWJY3++SeTC/AgBmA4AXUClUJs9ver8fKsAAYEUJCws4G21dXNB1oFdD/Qz8DQTk4C+bm2dn6DZ9bRiDQ8iAEYt8CoBpK5YBIYw0AEEZwSXX4oMB4PYoC6gCzAcDqrjGzEsMfen2xEmbMv1rSTjRi26dqRjShz9o2+6WQjBrSShQSkZAIADvW/HLrLY6cAAAAASUVORK5CYII=");
}

@media only screen and (max-width: 992px) {
  .PopupContent {
    width: 87%;
  }
}


@media only screen and (max-width: 630px) {
  .PopupContent h1 {
    font-size: 16px;
    margin-top: 13px;
  }

  .PopupContent H3 {
    font-size: 18px;
  }

  .bna-section {
    background: #ffffff;
    padding: 8px 5px 4px 4px;
    box-shadow: -3px 3px 15px #cfcfcf;
    border-radius: 20px;
    text-align: center;
    margin-top: 31px;
    min-width: 98px;
  }

  .Bank-section {
    width: 96%;
  }

  .bna-section img {
    max-width: 52px;
  }

  .desc {
    font-size: 14px;
  }

  .popuptitel {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}






/* Start Product Page */
.product-page {
  background: #F5F6F9;
  padding-top: 27px;

}

section.top-content {
  background: white;
}

.product-titel {
  font-size: 24px;
  color: #293847;
  border-top: 1px solid #D6D6D6;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #D6D6D6;
  font-weight: 600;
}

.product-price {
  font-size: 23px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid #feb5a5;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 18px;
  color: #fff8f8;
  display: flex;
  justify-content: center;
  background: linear-gradient(45deg, #fa7d98, #febba6);
  align-items: center;
}

.product-price span {
  font-size: 14px;
  font-weight: 500;
}

.product-mark,
.product-desc {
  font-weight: normal;
}

.product-mark span,
.product-desc span {
  color: #6E6E6E;
  font-size: 14px;
  font-weight: bold;
}

.product-purchase {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-q-p {
  display: flex;
  flex-wrap: wrap;
}


@media only screen and (max-width: 992px) {
  .product-purchase {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart .sell-btn {
    margin-left: auto !important;
    margin-right: auto !important;

  }

  .sell-btn {
    margin-left: 20px !important;
  }

  .product-purchase .product-quantity,
  .product-purchase .sell-btn,
  .product-purchase .call-btn {
    margin-top: 20px;
  }

  .product-attr {
    margin-top: 20px;
  }
}

.image-gallery-thumbnail {
  width: 66px !important;
}

.image-gallery-thumbnails-wrapper.left,
.image-gallery-thumbnails-wrapper.right {
  width: 64px !important;
}

@media only screen and (max-width: 1400px) {

  .image-gallery-content img.image-gallery-thumbnail-image {
    max-height: 52px !important;
    min-height: 52px !important;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1400px) {

  .call-btn {
    background: linear-gradient(231deg, #ffc0ba, #ef5f80);
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    padding: 7px 23px;
    border-radius: 25px;
    font-size: 20px;
    margin-left: 0px !important;
    margin-top: 22px;
    box-shadow: 4px 5px 18px #00000038;
  }
}


@media only screen and (max-width: 1200px) {
  .product-attr {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}

.product-quantity {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.cart .quantity-btn {
  margin-left: 0px;
  font-size: 17px;
}

.quantity-btn {
  margin-left: 22px;
  font-size: 17px;
}

button.qtyplus,
button.qtyminus {
  border-radius: 100%;
  border: none;
  background: white;
  color: black;
  width: 29px;
  height: 29px;
}

.qtyinput {
  border: none;
  background: none;
  text-align: center;
  width: 45px;
  padding: 0px;
  font-size: 24px;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cart .sell-btn {
  /* background: linear-gradient(231deg, #e1234f, #ffabb0); */
  background: linear-gradient(231deg, #91db63, #4de57c);
  font-size: 17px;
}

.sell-btn {
  /* background: linear-gradient(231deg, #36C59E, #7EF2FF); */
  background: linear-gradient(231deg, #91db63, #4de57c);
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 7px 23px;
  border-radius: 25px;
  font-size: 20px;
  margin-left: 24px;
  box-shadow: 4px 5px 18px #00000038;
  cursor: pointer;
  transition: all .9s;
  font-weight: 600;
}

.sell-btn svg {
  transform: rotateY(1deg);
  transition: transform 0.8s;
}

.sell-btn:hover svg {
  transition: all .9s;
  transform: rotateY(191deg);
}

.sell-btn:hover {
  box-shadow: -4px 2px 20px #84d872;
  border-radius: 6px;
}

.call-btn {
  background: linear-gradient(231deg, #ffc0ba, #ef5f80);
  width: fit-content;
  color: white;
  padding: 7px 23px;
  border-radius: 25px;
  font-size: 20px;
  margin-left: 24px;
  box-shadow: 4px 5px 18px #00000038;
}

.call-btn svg,
.sell-btn svg {
  margin-left: 14px;
}

.taksit-titel {
  display: flex;
  align-items: center;
}

.taksit-titel p {
  border: 1px solid;
  width: fit-content;
  padding: 1px 24px;
  border-radius: 33px;
  color: #A4C37D;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-right: 17px;
  line-height: 20px;
}

.product-taksit-list-price p {
  background: white;
  width: fit-content;
  border-radius: 20px;
  padding: 2px 22px;
}

.product-taksit-list-price p {
  background: linear-gradient(45deg, #fa7d98, #febba6);
  width: fit-content;
  border-radius: 20px;
  padding: 2px 22px;
  font-size: 15px;
  font-weight: bold;
  box-shadow: -3px 1px 20px #0000001c;
  color: #ffffff;
}

.product-desc ul#pills-tab {
  background: white;
  width: fit-content;
  padding: 9px 34px;
  border-radius: 25px;
  box-shadow: -3px 3px 20px #00000024;
}

@media only screen and (max-width: 600px) {

  .product-desc ul#pills-tab {
    margin: auto;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 700px) {
  .product-page .sell-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    margin: 0 !important;
    z-index: +999;
    height: 56px;
    display: flex;
    left: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

.product-desc .product-desc-btn.active {
  background: #F84771 !important;
  border-radius: 28px !important;
  padding: 4px 20px;
  color: white !important;
}

.product-desc .product-desc-btn.active svg {
  fill: white;
}

.product-desc .product-desc-btn {
  color: black;
  padding: 4px 20px;
}

.product-desc .product-desc-btn svg {
  fill: black;
}

.product-desc .product-desc-btn:hover {
  color: #818286;
}

.product-desc div#pills-tabContent {
  background: white;
  border-radius: 28px;
  min-height: 301px;
  box-shadow: -3px 3px 20px #00000024;
  padding: 39px;
}


.product-taksit-section {
  /* border-bottom: 1px solid #AAC783; */
  padding-bottom: 25px;
}

.line {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #A4C37D;
  vertical-align: middle;
}

.product-page-price {
  align-items: flex-start;
  padding: 14px 19px;
  background: none;
}

.product-page-price .tag-taksit {
  background: #F84771;
  padding: 0px 19px;
  line-height: 20px;
  border-radius: 21px;
  margin-bottom: 4px;
  color: #ffffff;
  font-weight: 700;
  font-size: 17px;
}

.product-page-price .price {
  color: #5d5e62;
  font-size: 17px;
  font-weight: 700;
}

.product-attr-item {
  display: flex;
  align-items: center;
  line-height: 16px;
}

.product-attr-item p {
  margin-bottom: 0;
  margin-left: 12px;
}

.product-attr {
  display: flex;
  flex-wrap: wrap;
}

.price-section {
  display: flex;
  flex-wrap: wrap;
}

.product-page .img-product {
  min-height: 140px;
  max-height: 140px;
  overflow: hidden;
}

.product-page .img-product img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 166px;
  border-radius: 6px;
}

span.product-mounth-time {
  font-size: 15px;
  font-weight: 400;
}

span.product-mounth {
  font-size: 10px;
  margin-right: 8px;
}

.image-gallery-content.fullscreen {
  background: white !important;
}

.image-gallery-content:not(.fullscreen) .image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none !important;
  border: 2px solid #FFA8BC !important;
  border-radius: 22px;
  box-shadow: 0px 0px 8px #ffa8bc;
  background: white;
}

.image-gallery-content:not(.fullscreen) .image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none !important;
  border: 2px solid #FFA8BC !important;
  border-radius: 22px;
  box-shadow: 0px 0px 8px #ffa8bc;
  background: white;
}

.image-gallery-content:not(.fullscreen) .image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: 2px solid #FFA8BC;
  border-radius: 22px;
  box-shadow: 0px 0px 8px #ffa8bc;
  background: white;
}

.image-gallery-content:not(.fullscreen) .image-gallery-thumbnail:hover {
  outline: none !important;
  border: 2px solid #FFA8BC !important;
  border-radius: 22px;
  box-shadow: 0px 0px 8px #ffa8bc;
  background: white;
}

.image-gallery-content:not(.fullscreen) .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 23px !important;
}

.fullscreen .image-gallery-fullscreen-button {
  top: -1px;
  margin-top: auto;
  z-index: +9;
  bottom: auto;
  background: #e35274;
  border-radius: 0px 0px 20px 20px;
  right: 10px;
}

.image-gallery-icon:hover {
  color: #ff5757;
}

.image-gallery-slide-wrapper.left,
.image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 74px) !important;
  border-radius: 19px;
  overflow: hidden;
}

.fullscreen .image-gallery-fullscreen-button svg {
  display: none;
}

.fullscreen .image-gallery-fullscreen-button::before {
  content: 'X';
  display: flex;
  width: 19px;
  height: 15px;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}

.bg-product {
  background: white;
}

.product-btn-exist p {
  background: #484747;
  width: fit-content;
  padding: 6px 16px;
  border-radius: 19px;
  margin-bottom: 0;
  color: white;
}

.product-btn-exist span {
  font-size: 13px;
  color: #4a4647;
}

/* End Product Page */




/* Start Account Page */

.account {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 47px;
  padding-bottom: 110px;
  background: linear-gradient(45deg, #f3f3f3, white);
}

.account .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: linear-gradient(45deg, #fa7d98, #febba6);

}

.account .nav-link {
  color: #545454;
}

.account button.login-btn {
  border: none;
  background: linear-gradient(45deg, #ee355c, #ffa2b5);
  padding: 10px 20px;
  width: 100%;
  border-radius: 20px;
  color: white;
}

.account .btn-google {
  background: linear-gradient(45deg, #e3e3e3, #ffffff) !important;
  color: #000000 !important;
}

.account button.login-btn-fb {
  border: none;
  background: linear-gradient(45deg, #005efa, #00a4f8);
  padding: 0px 8px;
  width: max-content;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  margin: auto;
  text-align: center;
}

.or-div {
  display: flex;
  align-items: center;
  color: #696969;
}

.or-div:before,
.or-div:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #696969;
}

.or-div:before {
  margin: 0 10px 0 0;
}

.or-div:after {
  margin: 0 0 0 10px;
}


button.login-btn-fb svg {
  margin-right: 8px;
  width: 31px;
}

.account form {
  max-width: 700px;
}

.account .tab-pane {
  background: white;
  box-shadow: 0px -2px 20px #d5d5d5;
  border-radius: 19px;
  padding: 28px 20px 56px 20px;
}

.account .back-to-dd {
  color: #585858;
}

/* End Account Page */


/* Start Search Page */
.search-page {
  padding-bottom: 128px;
  background: #fafafa;
}

.search-page .form-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #565555;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #f84771;
  border-radius: 17px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-area {
  /* height: 98px; */
  width: 100%;
  text-align: center;
  /* background: linear-gradient(45deg, #35d4ee, #d2ffcf); */
  /* background: linear-gradient(45deg, #F4C5C3, #fad1a3); */
  padding-top: 73px;
  padding-bottom: 80px;
  margin-bottom: 57px;
  background: linear-gradient(45deg, #f33f6959, #ffffff4d), url('https://www.lg.com/africa_fr/images/home-appliances/HA_product_line_up-web.png');
  /* background: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgb(225 93 124)), url(https://www.lg.com/africa_fr/images/home-appliances/HA_product_line_up-web.png); */
  background-position: center;
}

.search-area .titel {
  color: white;
  text-shadow: 2px 2px 10px #0000003b;
  font-size: 31px;
}

.search-area .search {
  width: 59%;
  margin-bottom: auto;
  margin-top: 20px;
  height: 50px;
  background-color: #ffffffba;
  padding: 10px 26px;
  border-radius: 30px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

@media only screen and (max-width: 992px) {

  .search-area .search {
    width: 100%;
  }


}

.search-area .search-input {
  color: white;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  margin-top: 5px;
  caret-color: transparent;
  line-height: 20px;
  transition: width 0.4s linear
}

.search-area .search .search-input {
  padding: 0 10px;
  width: 100%;
  caret-color: #536bf6;
  font-size: 19px;
  font-weight: 300;
  color: black;
  transition: width 0.4s linear;
}


.search-area .search-icon {
  height: 34px;
  width: 34px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #e0234f;
  font-size: 10px;
  bottom: 30px;
  position: relative;
  border-radius: 5px;
}

.search-area .search-icon:hover {

  color: #fff !important;
}

.sidebar-wrapper.sidebar--categories {
  background: white;
  padding: 46px 21px;
  border-radius: 20px;
}

/* .search-page .card-product {
  box-shadow: -2px -1px 20px #d9d9d9;
  width: 258px;
} */

.search-page .card-product {
  box-shadow: -2px -1px 20px #d9d9d9;
  width: 238px;
  margin-bottom: 28px;
}

.search-page .first-section-product {
  padding: 10px;
  width: 100%;
}

.search-btn {
  background: linear-gradient(45deg, #fa7d98, #febba6);
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 7px 23px;
  border-radius: 25px;
  font-size: 20px;
  /* margin-left: 24px; */
  box-shadow: -2px -1px 13px #0000001a;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.sidebar-wrapper .sidebarBlock-heading {
  font-size: 18px;
  border-bottom: 1px solid #e4e7f0;
  display: block;
  padding-bottom: 10px;
  margin: 0 0 15px;
  position: relative;
}

.sidebar-wrapper .sidebarBlock-heading:before {
  content: "";
  background-color: #f84771;
  height: 2px;
  width: 50px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.search-checkbox {
  font-size: 14px;
  font-weight: 500;
  color: #777777;
}

.form-check-input:checked {
  background-color: #f84771;
  border-color: #ffffff;
}

.sidebar-wrapper .sidebarBlock-heading svg {
  margin-right: 9px;
}

.search-page .img-product {
  min-height: 145px;
  max-height: 145px;
  overflow: hidden;
  text-align: center;
}

.search-mark {
  text-transform: capitalize;
}

/* End Search Page */

.image-gallery-content:not(.fullscreen) img.image-gallery-image {
  max-height: 400px !important;
  min-height: 400px !important;
  object-fit: cover;
}

.image-gallery-content img.image-gallery-thumbnail-image {
  max-height: 57px;
  min-height: 57px;
  object-fit: cover;
}

button.image-gallery-thumbnail {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #80808000;
}

.image-gallery-thumbnail {
  transition: none !important;
}


.separation {
  border-left: 1px solid #e4e7f0;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  margin: 0 4px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination a.active {
  background-color: #f84771;
  color: white;
  border: none;
  border-radius: 20px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}



/* Start Shopping Cart */
.cart {
  padding-bottom: 7px;
}

.cart a {
  color: black;
}

.cart .gosell {
  background: linear-gradient(231deg, #a1a1a1, #5c5c5c);
}

.cart-title {
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  color: #8b8b8b;
}

.cart .product-quantity {
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mon-panier {
  color: #e16f89;
  font-weight: 800;
  font-size: 15px;
}

.cart .remove-btn {
  text-align: center;
  /* border: 1px solid red; */
  border-radius: 24px;
  color: white;
  cursor: pointer;
  background: linear-gradient(45deg, #f56383, #ea3c64);
  width: fit-content;
  padding: 9px 15px;
  margin: auto;
}

.cart .remove-btn svg {
  height: 27px;
  width: 26px;
}

.product-item-img {
  max-height: 120px;
  overflow: hidden;
  width: 120px;
}

.cart .cart-img {
  max-width: 92px;
  min-height: 75px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 20px;
}

.cart .total-price {
  font-size: 23px;
  font-weight: 700;
  /* border-top: 1px solid #cfcfcf; */
  padding-top: 37px;
  display: flex;
  /* flex-direction: column; */
}

.cart .cart-price-taksit {
  font-size: 23px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
}

.cart .bank-img-simulation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  border-right: 2px solid #dbdbdb;
  padding-right: 19px;
}

.total-price span {
  font-size: 16px;
  font-weight: 500;
}

.cart .cart-product-section {
  border-bottom: 1px solid #cfcfcf;

}

.cart .cart-product-name {
  font-size: 12px;
  margin-bottom: 2px;
}

.cart-product-marque {
  margin-top: 0px;
  font-weight: 700;
  font-size: 12px;
}

.cart .qtyplus,
.cart .qtyminus {
  border-radius: 100%;
  border: none;
  background: #9d9999;
  color: white;
  width: 29px;
  height: 29px;
}

.cart .cart-product-price {
  font-weight: 700;
  color: #525252;
  font-size: 19px;
}

.cart-validation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  position: sticky;
  flex-wrap: wrap;
  justify-items: center;
  background: #f4f4f4;
  border-radius: 20px;
  padding-bottom: 51px;
  box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
  margin: auto;
  flex-direction: column;
}

@media only screen and (max-width: 1200px) and (min-width :992px) {
  .cart-validation {
    width: 99%;
  }

  .cart .cart-price-taksit {
    font-size: 19px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .total-price span {
    font-size: 14px;
    font-weight: 500;
  }
}

.cart .product-taksit-list-price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.cart .a-simulation {
  color: #3e3e3e;
  background: linear-gradient(231deg, #91db63, #4de57c);
  padding: 0px 10px;
  border-radius: 20px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.max-amount-cart {
  text-align: center;
  background: linear-gradient(45deg, #e7e7e7, #f3f3f3);
  padding: 10px 9px;
  border-radius: 6px 7px 7px 1px;
  box-shadow: 1px 5px 0px #c7c7c761;
}

.max-amount-cart span,
.min-amount-cart span {
  font-weight: 800;
  font-size: 20px;
  margin-left: 4px;
}

.cart .price-max-cart {
  font-weight: 800;
  font-size: 20px;
  margin-left: 4px;
  color: #ee4c71;
  border: 1px solid #ee4c71;
  padding: 0 4px;
  margin: 0 4px;
}

.min-amount-cart {
  text-align: center;
  background: linear-gradient(45deg, #E0234F, #FF517A);
  padding: 10px 20px;
  /* border-radius: 20px 7px 7px 1px; */
  box-shadow: 1px 5px 10px #c7c7c7;
  color: white;
  border: 5px solid #ffabac;
  display: flex;
  justify-content: center;
  align-items: center;
}

.min-amount-cart svg {
  height: 45px;
}

/* End Shopping Cart */




.cart-icon {
  position: relative;
}

.cart-icon span {
  position: absolute;
  top: -7px;
  background: #f22351;
  padding: 1px 7px;
  left: 11px;
  border-radius: 100%;
  color: white;
  font-weight: 800;
}



/*  */
.info-account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-account-header p {
  font-size: 20px;
  font-weight: 600;
}


.form-info {
  max-width: 739px;
  margin: auto;
  background: white;
  padding: 21px 42px;
  border-radius: 20px;
  box-shadow: 0px 3px 16px #20202014;
  padding-bottom: 82px;
}

@media only screen and (max-width: 992px) {
  .form-info {
    padding: 21px 11px;
  }

  .separation {
    border-left: none;
  }
}

.info-account {
  /* background: #e9e9e9; */
}

.info-step-titel {
  color: #eb3159;
  font-size: 21px;
  margin-top: 25px;
  font-weight: 600;
}

.multi-step-bar {
  overflow: hidden;
  counter-reset: step;
  width: 75%;
  margin: 15px auto 30px;
  margin-top: 66px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 992px) {
  ul.multi-step-bar p {
    display: none;
  }

  .multi-step-bar {
    width: 100%;
  }

  .info-account-header p {
    font-size: 17px;
  }
}

.multi-step-bar li {
  text-align: center;
  list-style-type: none;
  color: #545454;
  text-transform: CAPITALIZE;
  width: 16.65%;
  float: left;
  position: relative;
  font-weight: 300;
  line-height: 20px;
  cursor: pointer;
}

.multi-step-bar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  line-height: 30px;
  display: block;
  font-size: 12px;
  color: #484848;
  background: #e6e6e6;
  border-radius: 50%;
  margin: 0 auto 5px auto;
  box-shadow: 0 6px 20px 0 rgba(69, 90, 100, 0.15);
}

.multi-step-bar li::after {
  content: '';
  width: 100%;
  height: 2px;
  background: #ee355c;
  position: absolute;
  left: -50%;
  top: 15px;
  z-index: -1;
}

.multi-step-bar li:nth-child(2):after,
.multi-step-bar li:nth-child(3):after,
.multi-step-bar li:nth-child(4):after {
  content: '';
  width: 100%;
  height: 2px;
  background: #ee355c;
  position: absolute;
  left: -50%;
  top: 15px;
  z-index: -1;
}

.multi-step-bar li:first-child:after {
  content: none;
}

.multi-step-bar li.active:before {
  background: #eb3159;
  color: white;
}

.info-account .next-info-btn {
  border: none;
  background: linear-gradient(45deg, #686868, #8f8f8f);
  padding: 3px 22px;
  width: fit-content;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

.confirm-btn {
  padding: 20px 10px;
  border: none;
  background: linear-gradient(231deg, #91db63, #4de57c);
  padding: 7px 20px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}

.info-account .next-info-btn-disable {
  border: none;
  padding: 8px 32px;
  /* width: 36%; */
  border-radius: 20px;
  color: white;
  background: #dddddd;
  cursor: auto;

}

.info-account .cart .cart-img {
  max-width: 81px;
  min-height: 81px;
  object-fit: cover;
}

.info-account .cart-title {
  font-size: 20px;
  color: #8a8d8f;
}

.info-account .cart .cart-product-name {
  font-size: 10px;
}

.info-account .form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e3e3e;
  background-color: #f6f6f6;
  background-clip: padding-box;
  border: 1px solid #c6c6c6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.info-account .form-control::placeholder {
  color: #a7a7a7;
}

.info-account .form-select::placeholder {
  color: #a7a7a7;
}

.info-account .form-select {
  background-color: #f6f6f6;
  border: 1px solid #c6c6c6;
}

.info-account .form-label {
  margin-bottom: .5rem;
  color: #787878;
  font-weight: 600;
}

.info-account .checkout-price {
  font-size: 15px;
}

.alert-form {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
}

/*  */






/*  */
.popup-container {
  padding: 7px 49px;
}

.pre-simulation-popup {
  position: absolute;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  top: 0;
  /* left: 35%; */
  background: #40403eb3;
  width: 100%;
  z-index: +99999;
  min-height: 100vh;
  padding-bottom: 42px;
}

.pre-simulation-popup .label-days {
  font-weight: 700;
  color: #83858b;
}

.err-cart-sum {
  text-align: center;
  font-size: 15px;
  color: red;
}

.cart-sum-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 468px;
}

.cart-simulation-titel {
  text-align: center;
  margin-top: 80px;
  font-weight: 600;
  font-size: 27px;
  /* border: 1px solid #005043; */
  background: linear-gradient(45deg, #E0234F, #FF517A);
  color: white;
  /* border-radius: 21px; */
  border-radius: 21px 21px 0 0;
  width: -moz-fit-content;
  width: 100%;
  margin: auto;
  padding: 20px 14px;
  font-weight: 900;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-close-simulation {
  position: absolute;
  top: -12px;
  right: -16px;
  font-size: 18px;
  font-weight: 400;
  background: #ffffff;
  border-radius: 65px;
  padding: 8px 16px;
  cursor: pointer;
}

.btn-close-simulation div {
  color: #4c4b4b;
}

.cart-simulation-titel svg {
  width: 52px;
  height: 51px;
  margin-right: 22px;
  fill: white;
}

.cart-simulation-desc {
  margin-top: 25px;
  text-align: center;
  margin-bottom: 52px;
  border: 2px solid #e1e1e1;
  padding: 7px 9px;
  background: #f3f3f3a6;
  font-size: 14px;
  color: #545454;
}

.cart-simulation-popup {
  max-width: 546px;
  width: 97%;
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 20px;
  background: white;
  /* padding: 20px; */
  border-radius: 20px;
  box-shadow: -1px -1px 20px #b1b1b166;
  margin-top: 39px;
  /* overflow: hidden; */
}

@media only screen and (max-width: 560px) {
  .cart-simulation-popup {}
}

.remboursement-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.remb-box {
  background: #c7c7c7;
  height: 86px;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: white;
  border-radius: 13px;
  cursor: pointer;
  flex-direction: column;
}

.remb-box.active {
  background: linear-gradient(45deg, #E0234F, #FF517A);

}

.remb-box svg {
  /* margin-bottom: 18px; */
  width: 39px;
  height: 39px;
  fill: white;
  margin-bottom: 4px;
}

.rang-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: #646464;
  font-size: 18px;
}

.rang-popup-up {
  background: linear-gradient(45deg, #E0234F, #FF517A);
  padding: 3px 10px;
  border-radius: 19px;
  color: white;
  cursor: pointer;
  margin: 0 12px;
}

.btn-cart-simulation {
  border: none;
  padding: 4px 26px;
  background: linear-gradient(45deg, #6c6c6c, #686868);
  color: white;
  border-radius: 28px;
}

.max-amount {
  font-size: 27px;
  text-align: center;
  font-weight: 700;
  background: #ededed;
  color: #6f7172;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 23px 43px;
  border-radius: 18px;
  margin-top: 10px;
}

.cart-pre-sum {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.validation-section .titel {
  font-size: 19px;
  margin-bottom: 19px;
  font-weight: 400;
}

.user-info {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.user-info-items {
  margin-right: 20px;
}

p.user-info-items b {
  color: #7e8381;
}

.rang-popup span {
  font-size: 14px;
  font-weight: 400;
  margin-left: 7px;
}

.pre-simulation-popup .form-select {

  border: 1px solid #dbdbdb;
}

.pre-simulation-popup .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: linear-gradient(45deg, #E0234F, #FF517A);
  /* background: linear-gradient(45deg, #F4C5C3, #fad1a3); */
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  border-radius: 29px;
}

.pre-simulation-popup .slider:hover {
  opacity: 1;
}

.pre-simulation-popup .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  background: white;
  box-shadow: 0px 2px 17px #00000042;
  cursor: pointer;
  border-radius: 80px;
}

.pre-simulation-popup .slider::-moz-range-thumb {
  width: 40px;
  height: 40px;
  background: white;
  box-shadow: 0px 2px 17px #00000042;
  cursor: pointer;
  border-radius: 80px;

}

/*  */




/* Skeleton */
.slide-skeleton {
  min-height: 557px;
}

/*  */


/*  */
.carousel-item {
  max-height: 647px;
}

/*  */



/* Start Dar I3mar Section */
.dari3mar {
  background: #F5F6F9;
  scroll-behavior: smooth;
}



.dar-i3mar-header img {
  /* width: 100%;
  max-height: 738px;
  min-height: 738px;
  object-fit: cover;
  object-position: top; */
  width: 100%;
  max-height: 688px;
  min-height: 384px;
  object-fit: cover;
  object-position: top;
}

/* .dar-i3mar-box {
  -webkit-backdrop-filter: blur(16px);
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(16px);
  background-image: none;
  box-shadow: 0 25px 45px rgb(79 86 101 / 8%);
  width: 80%;
  min-height: 187px;
  border-radius: 37px;
  position: absolute;
  margin: auto;
  margin-top: -57px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
} */
.dar-i3mar-box {
  -webkit-backdrop-filter: blur(16px);
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(16px);
  background-image: none;
  box-shadow: 0 25px 45px rgb(79 86 101 / 8%);
  width: 80%;
  /* background: #ffffff69; */
  min-height: 187px;
  border-radius: 37px;
  /* position: absolute; */
  margin: auto;
  margin-top: -52px;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* -webkit-backdrop-filter: blur(5px); */
  /* backdrop-filter: blur(5px); */
  display: flex;
  justify-content: center;
  align-items: center;
  /* filter: blur(1.5rem); */
}

.box-item p {
  font-size: 20px;
}

.box-section {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  flex-wrap: wrap;
}

@media only screen and (max-width: 992px) {
  .box-section {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.box-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-width: 170px; */
  /* border-right: 1px solid #707070; */
  /* padding-right: 164px; */
  /* padding-left: 118px; */
  margin: 20px;
}

.box-item:last-child {
  border: none
}

.box-btn {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
  padding: 7px 20px;
  border-radius: 44px;
  color: white;
}

.category-dari3mar {
  margin-top: 40px;
  text-align: center;
  background: white;
  padding: 24px 0px;
  padding-bottom: 96px;
}

.category-dari3mar h1 {
  font-size: 32px;
  font-weight: 600;
  color: #747474;
}

.dar-i3mar-cat-card {
  width: 361px;
  height: 304px;
  background: white;
  border-radius: 43px;
  box-shadow: 2px 2px 18px #c7c7c736;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
}

.dar-i3mar-cat-card img {
  min-height: 246px;
}

.dar-i3mar-cat-card-p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dar-i3mar-cat-card-p p {
  margin-bottom: 0;
  font-size: 18px;
  color: #818080;
}

.dar-i3mar-cat-card svg {
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translate(-50%);
}

.dari3mar section.engagements {
  background: linear-gradient(45deg, #37D9E1, #7FF6AD);
  color: white;
  margin-top: 28px;
  padding: 76px 0px;
}

.dari3mar .titre:before,
.dari3mar .titre:after {
  content: "";
  flex-grow: 1;
  background: #ffffff;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

.description {
  padding: 162px 0px;
  position: relative;
  overflow: hidden;
}

.description .svg-bt {
  position: absolute;
  right: -368px;
  bottom: 33px;
}

.description .svg-tp {
  position: absolute;
  top: 49px;
  left: -82px;
}

.desc-titel {
  font-size: 37px;
  font-weight: 600;
  color: #8D8D8D;
  margin-bottom: 64px;
}

.desc-desc {
  font-size: 16px;
  color: #6a6767;
  max-width: 483px;
  margin-bottom: 47px;
}

.description .box-btn {
  background: linear-gradient(45deg, #7FF6AD, #37D9E1);
  padding: 7px 45px;
  border-radius: 44px;
  color: white;
  width: fit-content;
  font-weight: 600;
}

/* End Dar I3mar Section */



/* Start Dar Idjar Page  */

.daridjar-slide {
  display: flex;
  justify-content: center;
  min-height: 600px;
  position: relative;
}

.daridjar-slide img {
  width: 100%;
  object-fit: cover;
}

.daridjar-desc {
  padding: 88px 0;
  background: white;
}

.dar-idjar-page {
  background: #F5F6F9;
}

.dar-idjar-titel {
  font-size: 37px;
  color: #8D8D8D;
  line-height: 1;
}

.dar-idjar-titel span {
  font-weight: 800;
  color: #1DCA83;
}

p.dar-idjar-text {
  font-size: 15px;
  color: #747373;
}

.idjar-btn {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  padding: 5px 33px;
  border-radius: 44px;
  color: white;
  width: fit-content;
}

.dar-idjar-folder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dar-idjar-folder svg {
  width: 93px;
}

.dar-idjar-folder p {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  padding: 5px 28px;
  border-radius: 44px;
  color: white;
  width: fit-content;
  text-align: center;
}

@media only screen and (max-width: 1400px) and (min-width: 992px) {

  .dar-idjar-folder p {
    background: linear-gradient(45deg, #64D594, #2DD2C7);
    padding: 5px 7px;
    border-radius: 44px;
    color: white;
    width: -moz-fit-content;
    font-size: 15px;
    width: fit-content;
    text-align: center;
  }


}

.dar-idjar-titel {
  font-size: 37px;
  color: #8D8D8D;
  /* text-align: center; */
  line-height: 1;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.doc-daridjar {
  padding: 88px 0px;
}

.doc-text h1 {
  color: #717171;
  font-weight: 900;
  line-height: 1;
  font-size: 53px;
}

.doc-text p {
  font-size: 25px;
  font-weight: 600;
  color: #8E8E8E;
}

.doc-text span {
  color: #1DCA83;
}

.dar-idjar-page section.engagements {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  color: white;
  /* margin-top: 194px; */
  border-radius: 157px 157px 157px 0px;
  padding: 128px 0px;
  padding-top: 80px;
}

.dar-idjar-page .titre:before,
.dar-idjar-page .titre:after {
  content: "";
  flex-grow: 1;
  background: #ffffff;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

.daridjar-head {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  height: 372px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #64d59459, #2dd2c71c), url(https://weberteam.ca/wp-content/uploads/2017/03/weberteam-bg-livingroom.jpg);
  background-position: center;

}

.daridjar-head img {
  margin-bottom: -239px;
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  padding: 27px;
  border-radius: 20px;
}

.daridjar-dossier-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 65px;
  color: #103046;
  margin-top: 231px;
}

.dar-idjar-dossier-sub {
  font-size: 51px;
  color: #898989;
}



/*** VARIABLES ***/
/* Colors */
/*** EXTEND ***/
/* box-shadow */
ol.gradient-list>li::before,
ol.gradient-list>li {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}

/*** STYLE ***/
.dar-idjar-page main {
  display: block;
  margin: 0 auto;
  max-width: 40rem;
  padding: 1rem;
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
}

ol.gradient-list>li {
  background: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  margin-top: 1rem;
  min-height: 3rem;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
}

ol.gradient-list>li::before,
ol.gradient-list>li::after {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  border-radius: 1rem 1rem 0 1rem;
  content: "";
  height: 3rem;
  left: -1rem;
  overflow: hidden;
  position: absolute;
  top: -1rem;
  width: 3rem;
}

ol.gradient-list>li::before {
  align-items: flex-end;
  content: counter(gradient-counter);
  color: white;
  display: flex;
  font: 900 1.5em/1 "Montserrat";
  justify-content: flex-end;
  padding: 0.125em 0.25em;
  z-index: 1;
}

ol.gradient-list>li:nth-child(10n+1):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+2):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+3):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+4):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+5):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+6):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+7):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+8):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+9):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li:nth-child(10n+10):before {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
}

ol.gradient-list>li+li {
  margin-top: 2rem;
}



.dar-idjar-page .pre-simulation-popup {
  position: absolute;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* top: 324px; */
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  background: none;
  width: 500px;
  z-index: +999;
  min-height: auto;
  padding-bottom: 42px;
}



.dar-idjar-page .cart-simulation-titel {
  text-align: center;
  margin-top: 80px;
  font-weight: 600;
  font-size: 25px;
  /* border: 1px solid #005043; */
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  color: white;
  /* border-radius: 21px; */
  width: -moz-fit-content;
  width: 100%;
  margin: auto;
  padding: 19px 20px;
  font-weight: 900;
}

.dar-idjar-page .pre-simulation-popup .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  /* background: linear-gradient(45deg, #F4C5C3, #fad1a3); */
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  border-radius: 29px;
}

.dar-idjar-page .rang-popup-up {
  background: linear-gradient(45deg, #64D594, #2DD2C7);
  padding: 3px 10px;
  border-radius: 19px;
  color: white;
  cursor: pointer;
  margin: 0 12px;
}

.dar-idjar-page .remb-box.active {
  background: linear-gradient(45deg, #64D594, #2DD2C7) !important;
}

.dar-idjar-page .popup-container {
  padding: 45px 24px;
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.dar-idjar-page .popup-container input {
  border: 1px solid #dbdbdb;
  padding: 12px 13px;
}

.dar-idjar-page .btn-cart-simulation {
  border: none;
  padding: 8px 36px;
  background: linear-gradient(45deg, #6e6e6e, #5c5c5c);
  color: white;
  border-radius: 28px;
}

.dar-idjar-page .user-info {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
}

.dar-idjar-page .max-amount {
  font-size: 19px;
}

.dar-idjar-page .cart-simulation-desc span {
  font-size: 16px;
  font-weight: 800;
  color: #5a7fb1;
}

.dar-idjar-page .user-info-items {
  margin-right: 20px;
  margin-bottom: 7px;
}

@media only screen and (max-width: 580px) {
  .dar-idjar-page .pre-simulation-popup {

    width: 100%;
  }

  .rang-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    color: #646464;
    font-size: 17px;
  }

  .dar-idjar-page .cart-simulation-titel {

    font-size: 15px;
  }

  .dar-idjar-page .popup-container {
    padding: 40px 6px;
  }

}

/* End Dar Idjar Page  */



/* Start Dar Taksit */


/******************************************* background colors *******************************************/
.dar-taksit-page .background-section {
  background: #F5F6F9;
}

.dar-taksit-page .background-white {
  background: #fff;
}

section.slide-categories-content-categories {
  position: relative;
  min-height: 100%;
  background: white;
  border-radius: 20px;
}

/******************************************* height *******************************************/
.dar-taksit-page .height-500 {
  height: 500px !important;
}

/******************************************* Start slide-categories*******************************************/
ul.second-child {
  width: 497px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* min-height: 302px; */
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  height: 400px;
  transition: all 0s .2s;
}

.dar-taksit-page .slide-categories-content-categories ul.second-child>li {
  padding: 7px 0;
  min-width: 200px;
}

.dar-taksit-page .slide-categories-content {
  padding: 50px 0px;
}

.dar-taksit-page .slide-categories-slider .swiper {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.dar-taksit-page .slide-categories-slider .swiper-slide {
  max-height: 647px;
  overflow: hidden;
}

.dar-taksit-page .slide-categories-slider .swiper-slide img {
  max-width: 100%;
  max-height: 100%;
  margin: auto auto;
  object-fit: cover;
}

.dar-taksit-page .slide-categories-content-categories ul.first-child {
  padding-left: 0;
  /* background: #fff; */
}

.dar-taksit-page .slide-categories-content-categories ul li {
  /* position: relative; */
  list-style: none;
  display: flex;
  align-items: center;
  color: #8f8f8f;
  background: white;
  cursor: pointer;
}

.dar-taksit-page .slide-categories-content-categories ul.first-child>li {
  padding: 12px 0;
}

.dar-taksit-page .slide-categories-content-categories ul.second-child>li {
  padding: 7px 0;
}

.dar-taksit-page .slide-categories-content-categories ul li svg {
  margin-left: 20px;
}

.dar-taksit-page .slide-categories-content-categories ul span {
  display: inline-block;
  margin-left: 20px;
  color: #000;
  font-size: 14px;
}

.dar-taksit-page .slide-categories-content-categories ul .fa {
  position: absolute;
  right: 30px;
  font-size: 20px;
  transition: .5s;
}

.dar-taksit-page .slide-categories-content-categories ul.first-child>li:first-child {
  background: linear-gradient(45deg, #E0234F, #FF517A);
  color: #fff;
  border-radius: 10px 10px 0 0;
}

.dar-taksit-page .slide-categories-content-categories ul.first-child>li:first-child span {
  color: #fff;
}

.dar-taksit-page .slide-categories-content-categories .first-child>li:nth-child(2) {
  margin-top: 20px;
}

.dar-taksit-page .slide-categories-content-categories ul.second-child {
  /* display: none; */
  visibility: hidden;
  opacity: 0;
  background: #fff;
  padding: 1px 0;
  position: absolute;
  left: 95%;
  top: 48px;
  z-index: 10;
  box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
  /* min-height: 400px; */
  border-radius: 18px;
  transition: all 0s .2s;
}

.dar-taksit-page .slide-categories-content-categories ul.first-child>li:hover>ul.second-child {
  /* display: flex; */
  padding: 13px;
  visibility: visible;
  opacity: 1;
  transition: all 0s .2s;
}

@media only screen and (max-width: 992px) {

  section.slide-categories-content-categories .first-child {}

  section.mobile-dar-taksit-cat {
    margin-bottom: 20px;
    background: linear-gradient(45deg, #E0234F, #FF517A);
    padding: 10px 20px;
    border-radius: 9px;
    color: white;
    display: flex;
    justify-content: space-between;
  }

  .taksit-cat-mobile-list a {
    color: #747474;
  }

  .taksit-cat-mobile-list {
    margin-bottom: 20px;
  }

  .dar-taksit-page .slide-categories-content-categories ul.second-child {
    padding: 0 0 0 70px;
  }

  .dar-taksit-page .slide-categories-content-categories ul li {
    display: block;
    overflow: hidden;
  }

  .dar-taksit-page .slide-categories-content-categories ul.second-child li {
    padding: 10px 0;
  }

  .dar-taksit-page .slide-categories-content-categories ul.second-child {
    display: block;
    position: relative;
    left: 0;
    top: 0;
    box-shadow: none;
    height: 0;
    transition: .5s;
  }

  .dar-taksit-page .slide-categories-content-categories ul.first-child>li:hover ul.second-child {
    height: initial;
    transition: .5s;
  }

  .dar-taksit-page .slide-categories-content-categories ul.second-child li a {
    position: relative;
    left: 0;
    top: 0;
    box-shadow: none;
  }

  .dar-taksit-page .slide-categories-content-categories ul.first-child>li:hover .fa {
    transform: rotate(90deg);
  }
}

.dar-taksit-page .slide-categories-content-categories ul.second-child li a {
  color: #333333;
  white-space: nowrap;
  padding: 0 30px;
  font-size: 14px;
}

/* .slide-categories-content-categories ul.first-child>li:hover,
.slide-categories-content-categories ul.first-child>li:hover ul.second-child li:hover {
  background: #ffcaca;
} */

/* .slide-categories-content-categories ul.first-child > li:hover ul.second-child{
  display: block;
} */





.dar-taksit-page .card-product-dartaksit {
  position: relative;
  height: 210px;
  width: 252px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;

}

.dar-taksit-page .card-product-dartaksit .image-product {
  height: 189px;
  width: 100%;
  position: relative;
}

.dar-taksit-page .card-product-dartaksit .image-product .img-fluid-dartaksit {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.dar-taksit-page .card-product-dartaksit .title-product-dartaksit {
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background: linear-gradient(45deg, #f84771, #f847719e);
  color: #fff;
  padding: 6px 7px;
  border-radius: 20px;
  width: 85%;
  text-align: center;
}

.dar-taksit-page .price-product {
  background: #fff;
  align-items: flex-start;
  padding: 13px 0 13px 20px;
}

.dar-taksit-page .tag-taksit {
  color: #fff;
  background: #F84771;
  padding: 2px 25px;
  line-height: 1;
}

.dar-taksit-page .price {
  color: #5B5B5B;
  /* font-size: 20px; */
  font-size: 17px;
  width: 100%;
  text-align: start;
}


/******************************************* End slide-categories *******************************************/

/******************************************* Start dartaksit categories *******************************************/
.dar-taksit-page .card-dartaksit-category {
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  height: 205px;
  position: relative;
  border-radius: 10px;
}

.dar-taksit-page .title-section span {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 7px;
  z-index: 1;
  background: #f5f6f9;
  padding: 0 0 0 16px;
  color: #605E5E;
  font-size: 16px;
}

.first-section-dartaksit-category {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dar-taksit-page .card-dartaksit-category .first-section-dartaksit-category {
  flex-grow: 1;
}

.dar-taksit-page .card-dartaksit-category .second-section-dartaksit-category {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

svg.menu-btn {
  position: absolute;
  right: 30px;
  font-size: 20px;
  transition: .5s;
}

.cat-btn {
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-right: 19px;
  padding-bottom: 28px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.btn-cat-dar-taksit {
  background: linear-gradient(45deg, #1374E2, #30ACFF);
  padding: 7px 24px;
  border-radius: 22px;
  color: white;
  font-size: 11px;
  display: flex;
  z-index: +999;
}

@media only screen and (max-width: 1200px) and (min-width: 600px) {
  .cat-btn {
    padding-bottom: 10px;
  }

  .dar-taksit-page .card-dartaksit-category {
    height: 148px;
  }

  .btn-cat-dar-taksit {
    background: linear-gradient(45deg, #1374E2, #30ACFF);
    padding: 5px 13px;
    border-radius: 22px;
    color: white;
    font-size: 10px;
    display: flex;
  }

  .price span {
    font-size: 10px;
    font-weight: normal;
  }
}



@media only screen and (max-width: 600px) {
  .cat-btn {
    bottom: -63px;
    display: none;
  }

  .dar-taksit-page .title-dartaksit-category {
    font-size: 12px !important;
    color: #086ad8;
    text-align: left;
    width: 100%;
  }
}

.dar-taksit-page .img-dartaksit-category {
  max-width: 82px;
  min-height: 82px;
  min-width: 82px;
  max-height: 82px;
  object-fit: cover;
  margin-right: 27px;
  margin-left: 18px;
}

.dar-taksit-page .marque-dartaksit-category {
  color: #b5b5b5;
  font-size: 13px;
  font-weight: 700;
  width: 100%;
  text-align: start;
}

.dar-taksit-page .title-dartaksit-category {
  font-size: 14px;
  color: #086ad8;
  text-align: left;
  width: 100%;
}

.dar-taksit-page .tag-dartaksit-category {
  font-size: 14px;
  font-weight: 600;
  background: #F84771;
  color: #fff;
  padding: 3px 26px;
  border-radius: 20px;
  margin: 10px 0;
  line-height: 1;
}

.dar-taksit-page .price-dartaksit-category {
  color: #333333;
  font-size: 20px;
  font-weight: 700;
}

.dar-taksit-page .price-dartaksit-category span {
  font-size: 11px;
  font-weight: normal;
}

.dar-taksit-page .voir-plus-dartaksit-category {
  position: absolute;
  bottom: 0;
  right: 20px;
  color: #fff;
  padding: 5px 17px;
  border-radius: 20px;
  font-size: 12px;
}

.dar-taksit-page .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.dar-taksit-page .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.dar-taksit-page .carousel-control-prev-dartaksit-category,
.dar-taksit-page .carousel-control-next-dartaksit-category {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 7%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

.dar-taksit-page .carousel-control-prev-dartaksit-category {
  left: 0;
  transform: translateX(-90%);
}

.dar-taksit-page .carousel-control-next-dartaksit-category {
  right: 0;
  transform: translateX(90%);
}

.dar-taksit-page .carousel-dartaksit-category {
  position: relative;
  /* margin-left: 50px; */
  /* margin-right: 50px; */
}

.dar-taksit-page .electromenager-color {
  background: #1e89ed;
}

.dar-taksit-page .refrigérateur-color {
  background: #343e4a;
}

.dar-taksit-page .lave-linge-color {
  background: #ffa496;
}

.dar-taksit-page .chambre-adulte-color {
  background: #cd957f;
}

.dar-taksit-page .chambre-enfant-color {
  background: #cd957f;
}

.dar-taksit-page .carousel-control-prev-dartaksit-category {
  right: 25px !important;
  left: auto;
  transform: translateY(-55%);
}

.dar-taksit-page .carousel-control-next-dartaksit-category {
  right: 0 !important;
  transform: translateY(-55%);
}

.dar-taksit-page .carousel-control-prev-dartaksit-category,
.dar-taksit-page .carousel-control-next-dartaksit-category {
  position: absolute;
  top: -18px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 2%;
  padding: 0;
  color: #fff;
  height: 20px;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

.taksit-cat-mobile .carousel-control-next-icon,
.taksit-cat-mobile .carousel-control-prev-icon {
  display: inline-block;
  width: 2rem !important;
  height: 6rem !important;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.dar-taksit-cat-section .title-section {
  margin-bottom: 34px;
}

/******************************************* End dartaksit categories *******************************************/


.all-product-taksit-link {
  font-weight: 800;
  color: #e0224e !important;
}

/* End Dar Taksit */




/* Start CartSimulation */


@media only screen and (max-width: 500px) {
  .popup-container {
    padding: 25px 10px;
  }

  .btn-close-simulation {
    position: absolute;
    top: -12px;
    right: 0;
    font-size: 18px;
    font-weight: 400;
    background: #ffffff;
    border-radius: 65px;
    padding: 8px 16px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 400px) {
  .popup-container {
    padding: 25px 0;
  }
}

.step2-simulation {
  background: white;
}

.step2-simulation .form-info {
  padding: 20px 15px;
}

.taksit-steps-img {
  border-radius: 58px;
  border: 1px solid #ee355c;
  width: fit-content;
  margin: auto;
  padding: 23px;
  min-width: 105px;
  background: #ee355c;
  margin-bottom: 22px;
}

.taksit-steps-img svg {
  width: 64px;
  height: 35px;
  fill: white;
}

.taksit-steps {
  background: white;
  box-shadow: 4px 1px 20px #00000021;
  padding: 53px 0px;
  border-radius: 20px;
}

.taksit-steps-titel {
  font-size: 27px;
  margin-bottom: 77px;
  font-weight: 700;
  color: #666666;
}

.pre-sim-repli {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.step2-simulation .form-info {
  margin: inherit;
}

.step2-simulation .next-info-btn {
  border: none;
  background: linear-gradient(45deg, #6c6c6c, #686868);
  padding: 4px 34px;
  width: fit-content;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  text-align: center;
  margin: auto;
}

.simulation-head {
  font-size: 20px;
  font-weight: 700;
  color: gray;
}

.simulation-alert-final {
  background: #eb3159;
  padding: 20px;
  color: white;
  border-radius: 13px;
  text-align: center;
}

.captcha-section {
  text-align: center;
  margin-top: 59px;
  font-weight: 600;
}

/* End CartSimulation */







/* Start Dashboard */

.dashboard .menu {
  min-height: 100vh;
  height: 100vh;
  /* background: linear-gradient(193deg, #E3427C, #D43561); */
  background: linear-gradient(193deg, #b7446e, #e57777);
  width: 328px;
  position: fixed;
  top: 0;
  left: 0;
  transition: .3s all;
  overflow: auto;
  padding-bottom: 80px;
}

.btn-dashboard-close {
  cursor: pointer;

}

.dashboard .menu-head {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dashboard .menu-separation {
  border: 1px solid #ffffffb3;
  margin-top: 63px;
  width: 69%;
  margin-left: auto;
  margin-right: auto;
}

.dashboard .menu-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.menu-active {
  background: white;
  border-radius: 27px;
  color: #d83969;
  /* justify-content: space-around; */
}

.menu-active {

  align-items: center;
  font-weight: 800 !important;
  color: #d83969 !important;
  text-decoration: none;
}

.menu-active svg {
  margin-right: 20px;
  width: 24px
}

.menu-active svg path {
  fill: #d83969 !important;
}

.menu-url svg {
  margin-right: 28px;
  width: 24px
}

.menu-url svg path {
  fill: white;
}

.menu-url {
  color: white;
  font-size: 15px;
  width: 247px;
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 3px 17px;
  /* justify-content: space-around; */
  margin-top: 24px;
}

.menu-url a {
  display: flex;
}

.menu-logout {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.menu-logout p {
  color: #484848;
  background: white;
  padding: 7px 23px;
  border-radius: 18px;
  font-weight: 700;
  font-size: 14px;
}

.menu-logout {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: #AE254D; */
  background: #ae254d1f;
  padding-top: 82px;
  padding-bottom: 9px;
  border-radius: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.page-client {
  transition: .3s all;
  background: #f3f4f5;
  min-height: 100vh;
}

.menu-mobile-section svg {
  display: none;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {

  .page-client {
    margin-left: 0 !important;

  }

  .menu-mobile-section {
    height: 58px;
    background: linear-gradient(193deg, #b7446e, #e57777);
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2px 31px;
  }

  .menu-mobile-section svg {
    display: flex;
  }

  .menu {
    left: -328px;
  }
}

.page-title {
  font-size: 24px;
  font-weight: 900;
  color: #707070;
  margin-left: 64px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 20px;
}

.page-title span {
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
}

.page-content {
  background: white;
  padding: 49px;
  border-radius: 18px;
  width: 93%;
  margin: auto;
  margin-top: 80px;
  box-shadow: 3px 1px 20px #2626261f;
}

.box-info {
  background: linear-gradient(45deg, #2ac6d5, #4b86fe);
  height: 200px;
  padding: 47px;
  border-radius: 20px;
  color: white;
  padding-top: 34px
}

.box-info svg {
  margin-bottom: 16px;
  height: 37px;
  width: 36px;
}

.box-info p:nth-child(2) {
  font-size: 18px;
}

.box-info p:nth-child(3) {
  font-size: 38px;
  font-weight: 700;
}

.bg-g-second {
  background: linear-gradient(45deg, #f783ee, #797cfd) !important;
}

.bg-g-third {
  background: linear-gradient(45deg, #ff978c, #ff6db6) !important;
}




/* Table */
.dashboard-table {
  background: white;
  border-radius: 18px;
  width: 93%;
  margin: auto;
  margin-top: 26px;
  box-shadow: 2px -1px 20px 4px #26262612;
  border-radius: 20px;
  overflow: hidden;
}

.detail-btn {
  background: #84E0BE;
  width: fit-content;
  padding: 2px 12px;
  border-radius: 6px;
  font-size: 13px;
  margin: auto;
  cursor: pointer;
}

.dashboard-table img {
  max-width: 54px;
  max-height: 84px;
  overflow: hidden;
}

.dashboard-table table {
  /* border: 1px solid #ccc; */
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  font-size: 13px;
  word-break: break-word;
}

.dashboard-table table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.dashboard-table table thead tr:first-child {
  background: #F9F7F4;
  height: 59px;
  color: #100F0F;
}

.dashboard-table table tr {
  background-color: #ffffff;
  border-bottom: 2px solid #efecec;
  padding: .35em;
}

.dashboard-table table th,
.dashboard-table table td {
  padding: 26px 3px;
  text-align: center;
}

.dashboard-table table th {
  font-size: 14px;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .dashboard-table table {
    border: 0;
  }

  .dashboard-table table caption {
    font-size: 1.3em;
  }

  .dashboard-table table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .dashboard-table table tr {
    border-bottom: 25px solid #f0f1f2;
    display: block;
    margin-bottom: 0.625em;
  }

  .dashboard-table table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  .dashboard-table table td::before {
    /*
   * aria-label has no advantage, it won't be read inside a.dashboard-table table
   content: attr(aria-label);
   */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .dashboard-table table td:last-child {
    border-bottom: 0;
  }
}

.list-commandes-d {
  margin-bottom: 15px;
}

.diar-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dar-tab {
  background: linear-gradient(45deg, #d1d1d1, #bdbdbd);
  padding: 7px 25px;
  border-radius: 17px;
  margin: 1px 11px;
  color: white;
  cursor: pointer;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dar-tab:hover {
  background: #84e0be;
  transition: all .3s;

}

.dar-tab-active {
  background: linear-gradient(45deg, #f56383, #ea3c64) !important;
}

.r-btn {
  width: 11px;
  height: 11px;
  background: white;
  border-radius: 20px;
}

.no-results {
  min-height: 284px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashborad-back {
  font-size: 12px;
}


/* End Dashboard */






.icon-user {
  background: #f8234f;
  padding: 4px 14px;
  border-radius: 100%;
  font-size: 21px;
  text-transform: capitalize;
}


.screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ed345cad;
  display: flex;
  align-items: center;
  z-index: +99999999999999999999;
}

a.dossier-simulation {
  text-align: center;
  color: black;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: antiquewhite;
  width: fit-content;
  padding: 4px 23px;
  border-radius: 20px;
  margin: auto;
  cursor: pointer;
}

.modal {
  background: #0000007a;
}

.dossier-taksit-item {
  margin-bottom: 20px;
  font-size: 15px;
}

@media (min-width: 576px) {

  .info-account .modal-dialog {
    max-width: 808px;
    margin: 1.75rem auto;
  }
}

.formtaksit-final {
  text-align: center;
  color: black;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ee355c;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 24px;
  border-radius: 33px;
  margin: auto;
  cursor: pointer;
}

.formtaksit-final a {
  color: white;
}

.validation .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ee355c;
  border-color: #ffe4e9;
}

.loader {
  width: 100%;
  height: 15px;
  text-align: center;
}

.dot {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  display: inline-block;
}

.dot:first-child:before {
  animation-delay: 0ms;
}

.dot:first-child:after {
  animation-delay: 0ms;
}

.dot:last-child:before {
  animation-delay: 200ms;
}

.dot:last-child:after {
  animation-delay: 200ms;
}

.dot:before {
  content: "";
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: blue;
  animation-name: dotHover;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(.82, 0, .26, 1);
  animation-iteration-count: infinite;
  animation-delay: 100ms;
  background: white;
  border-radius: 100%;
}

.dot:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: black;
  box-shadow: 0px 0px 1px black;
  opacity: .20;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: -2px;
  border-radius: 100%;
  animation-name: dotShadow;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(.82, 0, .26, 1);
  animation-iteration-count: infinite;
  animation-delay: 100ms;
}

@keyframes dotShadow {
  0% {
    transform: scaleX(1);
  }

  50% {
    opacity: 0;
    transform: scaleX(.6);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes dotHover {
  0% {
    top: 0px;
  }

  50% {
    top: -50px;
    transform: scale(1.1);
  }

  100% {
    top: 0;
  }
}

span.note-form {
  font-size: 14px;
  color: #6e6767;
}




/* Cat Product */
.cat-title {
  margin-top: 80px;
  margin-bottom: 84px;
  font-size: 25px;
  font-weight: 600;
  border-bottom: 1px solid silver;
  padding-bottom: 23px;
}

.cat-title span {
  font-size: 16px;
  background: #f84670;
  color: white;
  padding: 5px 16px;
  margin-right: 15px;
  border-radius: 20px;
}


.blackpage {
  background: black;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
}



/* Start Assistance */

.assistence-page {
  background: #fbf6f6;
  min-height: 100vh;
}

.ass-tel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ass-tel h3 {
  margin-bottom: 0;
}

.ass-title {
  color: #ed345b;
}

/* End Assistence */



/*  */
.abstract-alsalam {
  /* background-image: url("https://www.diardzair.com.dz/uploads/img/logo/ASBA-04.png");
  background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: top left, 53%, 30%; */

  background-image: url("https://www.diardzair.com.dz/uploads/img/logo/ASBA-04.png");
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: -5% 39%;
}

.abstract-bna {
  background-repeat: no-repeat;
  /* background-size: cover; */
  /* background-position: top center, 58%, 23%; */
  background-image: url('./img/bnaabstract.png');
  /* background-position: center; */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: -4% 10%;
}

.bank-img-simulation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}

.bank-img-simulation img {
  max-width: 79px;
  border-radius: 20px;
}

.pre-simulation-popup .bank-img-simulation img {
  max-width: 53px;
}

.gradiant-bna {
  background: linear-gradient(45deg, #64D594, #2DD2C7) !important;
}

.gradiant-alsalam {
  background: linear-gradient(328deg, #005042, #53dfc6) !important;
}

/*  */



/*  */
.react-loading-skeleton {
  z-index: 0 !important;
}

/*  */




.taksit-dossier ol.gradient-list>li:nth-child(10n+1):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+2):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+3):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+4):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+5):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+6):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+7):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+8):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+9):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li:nth-child(10n+10):before {
  background: linear-gradient(45deg, #ff6666, #ff85a2);
}

.taksit-dossier ol.gradient-list>li+li {
  margin-top: 2rem;
}

.taksit-dossier .dar-idjar-dossier-sub {
  font-size: 28px;
  color: #898989;
}

.taksit-dossier .sell-btn-taksit {
  background: linear-gradient(231deg, #e1234f, #ffabb0);
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  padding: 7px 23px;
  border-radius: 25px;
  font-size: 20px;
  margin-left: 24px;
  box-shadow: 4px 5px 18px #00000038;
  cursor: pointer;
}



/* FAQ */
.faq-page .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #ee355c;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.faq-page .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
}

.faq-page .accordion-collapse {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  border: 1px solid rgb(238, 53, 92);
}

/* FAQ */


.taksit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  text-align: center;
  box-shadow: 0px 1px 19px #ff000070;
}

.taksit-btn a {
  padding: 3px 19px;
}

.product-cat {
  font-weight: 500;
  font-size: 14px;
  color: #ec3560;
  border-bottom: .5px solid #d5d5d563;
  padding-bottom: 11px;
}

.product-cat a {
  color: #545454;
}


/* Validation */
.btn-download a {
  background: #ee355c;
  width: fit-content;
  margin: auto;
  padding: 6px 23px;
  border-radius: 20px;
  margin-top: 7px;
  transition: all .3s;
}

.btn-download p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
  color: #ee355c;
}

.btn-download a:hover {
  background: #a7a7a7;
  border-radius: 5px;
}

/* Validation */




/* Start Aide Page */
.Aide-page {
  background: #F5F7F9;
}

.Aide-page .masthead {
  height: 100vh;
  min-height: 500px;
  /* background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Aide-page .aide-header-title {
  font-size: 56px;
  color: white;
  font-weight: 700;
}

.Aide-page .aide-header-sub {
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.Aide-page .aide-header-btn {
  background: linear-gradient(247deg, #E01C3B, #FB807C);
  color: white;
  padding: 10px 28px;
  border-radius: 23px;
  box-shadow: 0px 8px 14px #e01c3b69;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 21px;
  margin: auto;
  font-weight: 600;
}

.Aide-page .etap-title {
  color: #363C5A;
  font-size: 32px;
  font-weight: 700;
}

.Aide-page .etap-sub {
  color: #363C5A;
  font-size: 20px;
  font-weight: 500;
}

.Aide-page .etap-item {
  background: white;
  padding: 37px;
  border-radius: 15px;
  position: relative;
  padding-top: 103px;
}

.Aide-page .etap-item-title {
  font-size: 20px;
  color: #D82F57;
  font-weight: 800;
}

.Aide-page .etap-item-sub {
  color: #5C6272;
  font-weight: 900;
}

.Aide-page .etap-item img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -133%);
}


.Aide-page .avantage-item {
  background: white;
  padding: 37px;
  border-radius: 25px;
  box-shadow: 5px 18px 20px #5c5c5c0d;
  min-height: 319px;
}

.Aide-page .avantage-item-title {
  font-size: 24px;
  font-weight: 500;
  color: #CC1330;
}

.Aide-page .avantage-item-title svg {
  width: 100px;
}

.Aide-page .avantage-item p {
  color: #5c5a5a;
}

.Aide-page .bg-padding {
  background: white;
  padding-bottom: 200px;
  margin-top: 116px;
}

.Aide-page .accordion-item {
  padding: 16px;
  margin-bottom: 29px;
  border-radius: 24px !important;
  box-shadow: -1px 3px 10px #80808026;
}

.Aide-page .accordion-button:not(.collapsed) {
  color: #CC1330;
  font-size: 24px;
}

.Aide-page .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 19px;
  color: #CC1330;
  font-weight: 600;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.Aide-page .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.Aide-page .accordion-body {
  color: #585656;
}




.junk-css {
  padding-bottom: 67.3076923076923%;
  width: 100%;
}

.diar-animation {
  position: absolute;
  top: 0;
  /* left: 0; */
  height: 100%;
  width: 100%;
  background: #e4e7ea;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 31px;
}

.diar-card {
  height: 100%;
  position: relative;
  /* margin-bottom: 40px; */
  box-shadow: 0 20px 30pxrgba(0, 0, 0, .1);
  transition: all .3s cubic-bezier(.65, .05, .36, 1);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 .25remrgba(13, 110, 253, .25);
  border: none !important;
  box-shadow: none !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, .7) !important;
}



.i3mar-panier {
  background: #ffffffad;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: -1px 2px 14px #00000078;
  backdrop-filter: blur(15px);
  padding: 4px 0px;
}

.i3mar-panier p {
  margin-bottom: 0;

}

.i3mar-panier a {
  color: #2c2c2c;

}



/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #f84670, #e42854);
  /* background: pink;  */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@media only screen and (max-width: 700px) {
  .taksit-cat-slider img {
    max-height: 362px;
  }
}

.swiper-pagination-bullet-active {
  background: #ef3863 !important;
}



/* Call Us */


.Callus {
  position: fixed;
  bottom: 0%;
  right: 0%;
  transform: translate(-50%, -50%);
  z-index: +9999;
}

.Callus svg {
  width: 29px;
}

.Callus ul {
  list-style: none;
}

.Callus ul li {
  width: 50px;
  height: 50px;
  position: relative;
  background: linear-gradient(231deg, #91db63, #4de57c);
  margin: 10px 0;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Callus ul li .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
}

/* .Callus ul li.facebook{
  background: #3b5998;
} */


.Callus ul li.facebook div.slider {
  background: #e5e5e5;
}



.Callus .slider {
  content: "";
  position: absolute;
  top: 12%;
  right: 51px;
  display: flex;
  justify-content: center;
  height: 37px;
  width: 0px;
  background: #eebb5c;
  border-radius: 20px;
  transition: all 0.5s 0.3s ease;
  align-items: center;
}

.Callus .slider p {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: #6d6d6d;
  text-align: center;
  /* line-height: 50px; */
  opacity: 0;
  transition: all 0.6s ease;
  margin-bottom: 0px;
}

.Callus ul li:hover .slider {
  width: 180px;
  transition: all 0.5s ease;
}

.Callus ul li:hover .slider p {
  opacity: 1;
  transition: all 1s 0.2s ease;
}


/* Call Us */




.btn-re {
  width: 19px;
  margin-right: 13px;
}

.btn-next {
  width: 19px;
  margin-left: 13px;
}



div#buttonDiv {
  border-radius: 22px;
  overflow: hidden;
  border: 1px solid #d2d2d2;
  margin: auto;
  width: fit-content;
}



.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  display: none;
}





.non-clickable{
  cursor: auto !important;
  opacity: .5;
}

.non-clickable:hover{
  background: none !important;
}